.toast-notification {
  position: fixed !important;
  bottom: 0% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999 !important;
  transition: ease-in 0.1;
  width: 100%;
  min-width: 250px;
  max-width: 350px;
}

.react-bootstrap-table.table-responsive.table-dark {
  min-height: 279px;

}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
  .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: left;
  }
}

.field-error {
  color: red;
  font-size: 12px;
  font-weight: 500;
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

.contact.col-lg-6.col-sm-12.mt-2 {
  margin-right: -73px;
}

.contact.col-lg-6.col-sm-12.mt-3 {
  margin-right: -73px;
}

.followers.col-lg-6.col-xl-3 {
  margin-top: -27px;
}

.bg-secondary-custom {
  background-color: #525e73 !important;
  color: #fff !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Chapter-Picture {
  height: 296px;
  width: 194px;
  border-radius: 3px;
  border: 1px solid rgb(226 226 226);
  object-fit: cover;
  margin-top: 30px;
  margin-bottom: 10px;
}

.content {
  font-family: inherit;
}

span.moment_date {
  margin-left: 20px;
  color: darkgrey;
  font-size: small;
}

.talent_image {
  width: 40px;
  height: 40px;
  background-color: #c7c7c7;
  border-radius: 20px;
  object-fit: cover;
}

.toast-notification i {
  font-size: 20px;
}

.create-event-modal textarea {
  resize: none;
}

.dropzone {
  border-radius: 10px;
  border: 2px dashed #1aae6f;
  text-align: center;
  cursor: grabbing;
}

.create-event-modal label {
  color: #000;
  font-weight: bolder;
}

.message_bookings {
  white-space: pre-line;
}

.gmap-wrapper {
  object-fit: contain;
  position: relative;
  width: 100%;
  background-color: aqua;
}

.truncate {
  min-width: 200px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-x: hidden;
  resize: horizontal
}

.chat {
  bottom: 20px;
  right: 40px;
  width: 100%;
  max-width: 350px;
  height: 500px;
}

.deleted-user {
  background-color: #900C3F;
  opacity: 0.4;
  transition: 0.4s;
}

.chat .chat-body {
  height: 400px;
  overflow-y: scroll;
}

.chat .chat-body::-webkit-scrollbar {
  display: none;
}

.chat .chat-footer i {
  cursor: pointer;
}

.fa-times {
  cursor: pointer !important;
}

i.clip-board {
  right: 25px;
  top: 0px;
  cursor: pointer !important;
}

/* .chat .chat-footer {
    bottom: 20px;
    right: 8%;
    z-index: 99;
} */

.user-sended-chat {
  width: 80%;
  min-height: 20px;
  float: left;
  position: relative;
}

.admin-sended-chat {
  width: 80%;
  min-height: 20px;
  float: right;
  position: relative;
}

.user-sended-chat p,
.admin-sended-chat p {
  min-height: 10px;
  height: auto;
  max-height: 100%;
  border-radius: 10px 10px 0px 10px;
  padding: 5px 10px;
}

.user-sended-chat::before {
  color: #fff;
}

.admin-sended-chat::before {
  color: #5e72e4;
}

.user-sended-chat::before,
.admin-sended-chat::before {
  content: '\f111';
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 5px;
  right: -2%;
  bottom: 10px;
  position: absolute
}

.admin-sended-chat .username,
.user-sended-chat .username {
  font-style: italic;
  font-size: 12px;
}

.event-images {
  object-fit: contain;
}

.event-images img {
  border-radius: 10px;
  max-width: 190px;
  display: block;
  margin: 0 auto;
}

.all-group-members {
  height: 80px;
  overflow-y: scroll;
}

.all-group-members div:hover {
  background-color: #5e72e4;
}

.toolbarClassName {
  padding: 20px;
  background-color: #1c345d;
  color: #4d7bca;
  border: 0px;
}

.editorClassName {
  padding: 20px;
  height: 250px;
}

.all-group-members::-webkit-scrollbar {
  display: none;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #5e72e4 !important;
  border-radius: 50%;
  padding: 20px;
  background-size: 18px;
}

.detail-dialog label+span {
  margin-left: 8px;
}

.detail-dialog label {
  font-weight: 600;
  color: #5e72e4;
}

.avatar-group {
  display: flex;
}

.visually-hidden {
  display: none !important;
}

.badge-danger {
  color: #fff;
  background-color: #d50000;
}

.badge-success {
  color: #fff;
  background-color: #1aae6f;
}

.badge-info {
  color: #fff;
  background-color: blue;
}

.html-viewer {
  height: 350px;
  resize: none;
  overflow-y: scroll;
}

.html-viewer::-webkit-scrollbar {
  width: 5px;
}

.html-viewer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

.html-viewer::-webkit-scrollbar-thumb {
  background: #5e72e4;
}

.html-viewer::-webkit-scrollbar-thumb:hover {
  background: #2a3ae2;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.toolbarClassName .rdw-option-wrapper {
  border: 1px solid #ffffff;
  background: #ffffff;
  color: #fff;
  border-radius: 4px;
  padding: 15px 8px;
}

.toolbarClassName .rdw-colorpicker-modal-options .rdw-option-wrapper {
  padding: 0px 0px;
}

.workout-details span {
  color: #000;
  font-size: 14px;
}

.ltg-gmap-wrapper {
  position: relative;
  height: 380px;
}

.upload-file-contianer {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 3px dashed #1aae6f;
}

.images i.fas.fa-times {
  font-size: 20px;
  color: #d50000;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control {
  color: #000;
}

.form-control:focus {
  color: #000;
  border-color: transparent;
  box-shadow: 0px 0px 0px 2px rgb(70 123 233);
}

.prpnx-thumbnail {
  width: 100px;
  height: 100px;
  border: 1px solid #c7c7c7;
  border-radius: 6px;
  display: inline-block;
  margin-top: 20px;
  position: relative;
  margin-right: 20px;
}

.prpnx-thumbnail img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 6px;
}

.prpnx-thumbnail-container {
  display: flex;
}

p.ql-align-justify {
  margin-bottom: 10px;
}

.col-lg-12.chapter-comments-text {
  padding-left: 6rem;
}

h3.chapter-comments-text {
  color: rgb(224, 120, 59);
}
span.chapter-badge.badge.badge-danger {
  font-size: x-small;
}

span.chapter-badge.badge.badge-success {
  font-size: x-small;
}
.prpnx-thumbnail-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 1px;
  width: 24px;
  height: 24px;
  border-radius: 35px;
  background-color: #af1717;
  cursor: pointer;
  transition: all .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ltgr-searchmap-wrapper {
  width: 100%;
  position: relative;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #cad1d7;
  margin-bottom: 20px;
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.places-autocomplete-wrapper {
  position: absolute;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  margin: 15px auto 0px;
}

.places-inputbox {
  height: 40px;
  width: 50%;
  border-width: 1px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-color: grey;
}

.ltgr-map {
  height: 100%;
}

.places-autocomplete-wrapper .location-search-input {
  box-shadow: 0px 4px 11px 0px rgb(0 0 0 / 20%);
  width: 320px;
  border-radius: 6px;
}

.autocomplete-dropdown-container {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 11px 0px rgb(0 0 0 / 20%);
  position: absolute;
  width: 100%;
  top: 110%;
  max-height: 170px;
  overflow: auto;
}

.autocomplete-dropdown-container .suggestion-item {
  padding: 6px 12px;
  border-bottom: 1px solid #ddd;
  transition: all 0.4s;
  cursor: pointer;
}

.suggestion-item.suggestion-item--active {
  background-color: #c7cfff;
  color: #5e72e4;
}

.prpnx-readonly .form-control {
  background-color: #f2f2f2 !important;
  pointer-events: none;
}

form.prpnx-readonly .btn,
form.prpnx-readonly .filepicker-zone {
  display: none;
}

iframe {
  display: none;
}

.react-tel-input .form-control {
  width: 100%;
}

.filepicker-zone {
  border-radius: 10px;
  border: 2px dashed #1aae6f;
  text-align: center;
  cursor: grabbing;
  display: block;
  padding: 20px;
}

.filepicker-document.image--profile:after {
  content: "Profile Picture";
  position: absolute;
  top: 96%;
  width: calc(100% + 2px);
  color: #fff;
  text-transform: uppercase;
  font-size: 10.4px;
  text-align: center;
  background-color: #e62e2d;
  border-radius: 0px 0px 6px 6px;
  left: -1px;
  font-family: 'Cerebri Book';
  padding: 2px 0px;
}


.filepicker-document {
  width: 100px;
  height: 100px;
  border: 1px solid #c7c7c7;
  border-radius: 6px;
  display: inline-block;
  margin-top: 20px;
  position: relative;
  margin-right: 20px;
}

.filepicker-document img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 6px;
}

.filepicker-document:first-child {
  margin-right: 0px;
}

.filepicker-document .btn-warning {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #af1717;
  padding: 1px;
  width: 28px;
  height: 28px;
  border-radius: 35px;
  cursor: pointer;
  transition: all 0.2s;
}

.profile-changer {
  position: absolute;
  background: #fff;
  border: 0;
  width: 30px;
  filter: invert(1);
  height: 30px;
  top: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 30px;
  cursor: pointer;
  z-index: 99;
  transition: all 0.3s;
  opacity: 0;
  transform: translateY(24px);
}

.filepicker-document:hover .profile-changer {
  opacity: 1;
  transform: translateY(0px);
}

.page--form {
  padding: 30px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  border-radius: 11px;
  box-shadow: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%);
  margin-bottom: 30px;
}

.floating-nav {
  position: fixed;
  right: 0;
  top: calc(50% - 130px);
  z-index: 9;
}

.floating-nav ul {
  background-color: #fff;
  padding: 0;
  list-style: none;
  padding: 0px;
  box-shadow: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%);
  margin-bottom: 0px;
  position: relative;
  right: -100%;
  transition: all 0.3s;
  height: 360px;
  overflow: auto;
  width: 320px;
}

.floating-nav.is--open ul {
  right: 0px;
}

.floating-nav ul li {
  padding: 14px 25px 7px;
  cursor: pointer;
}

.floating-nav .nav-toggle {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
  color: #fff;
  padding: 15px 15px;
  margin-left: auto;
  width: 50px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 7px 0px 0px 7px;
  cursor: pointer;
}

.floating-nav ul li.is--active {
  background-color: #c8e8ff;
}

.iyg-editor {
  height: 570px;
  margin-bottom: 44px;
}

.floating-nav h3 {
  font-size: 14px;
  font-weight: 700;
}

.floating-nav span {
  font-size: 13px;
  color: #818181;
}

.ql-editor b,
.ql-editor strong {
  font-weight: 700;
}

p.no-data {
  padding: 15px;
  text-align: center;
  font-weight: 500;
  background-color: #f6f6f6;
  color: #5d91b9;
}

p.no-data a {
  color: #000 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

td.iyg-col-narrow {
  max-width: 150px;
  white-space: normal;
}

.react-datepicker-popper {
  min-width: 330px;
}